<template>
  <div class="container">
    <md-card class="dash-content">
      <div class="header-wrapper">
        <PageHeader :title="id ? 'Update Page' : 'Add New Page'" />
        <div>
          <md-button :to="{ name: 'Pages Content' }" class="md-primary">
            Back
          </md-button>
        </div>
      </div>

      <div>
        <form novalidate @submit.prevent="validateUser">
          <div class="">
            <section class="row my-3" style="width: 100%">
              <div class="col-md-8">
                <div class="s-title">
                  <strong>Page Title</strong>
                </div>
                <md-field :class="getValidationClass('title')">
                  <label>Page title</label>
                  <md-input v-model="form.title" required></md-input>
                  <span class="md-error" v-if="!$v.form.title.required"
                    >Title is required</span
                  >
                </md-field>

                <md-field :class="getValidationClass('page_url')">
                  <label>Page Url</label>
                  <md-input
                    v-model="form.page_url"
                    type="url"
                    required
                  ></md-input>
                  <span class="md-error" v-if="!$v.form.page_url.required"
                    >Page url is required</span
                  >
                </md-field>
                <div style="width: 100%">
                  <md-checkbox v-model="form.status">Visibility </md-checkbox>
                </div>
              </div>
              <div class="col-md-4">
                <div class="s-title">
                  <strong>Page Meta</strong>
                </div>
                <md-field :class="getValidationClass('title')">
                  <label>Meta title</label>
                  <md-input v-model="form.meta_title"></md-input>
                </md-field>

                <md-field :class="getValidationClass('title')">
                  <label>Meta keywords</label>
                  <md-input v-model="form.meta_keywords"></md-input>
                </md-field>
                <md-field :class="getValidationClass('title')">
                  <label>Meta Description</label>
                  <md-textarea v-model="form.meta_description"></md-textarea>
                </md-field>
              </div>
            </section>
          </div>
          <mdc-button :loading="loading">{{
            id ? "Update Page" : "Create Page"
          }}</mdc-button>
        </form>
      </div>
    </md-card>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength, url } from "vuelidate/lib/validators";
import { createNamespacedHelpers } from "vuex";
const { mapActions, mapGetters } = createNamespacedHelpers("pages");

export default {
  mixins: [validationMixin],
  data: () => ({
    form: {},
    loading: false,
    metadata: null,
    fetching: true,
  }),
  validations: {
    form: {
      title: {
        required,
      },
      page_url: {
        required,
        url,
      },
    },
  },
  watch: {
    "form.section_id"(val) {
      let selected = this.metadata.sections_data.find(
        (section) => section.id == val
      );

      this.form.amount = selected.amount;
    },
  },
  methods: {
    ...mapActions(["createPage", "updatePage", "getPage"]),
    async validateUser() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let data = { ...this.form };

        data.status = data.status ? "live" : "draft";

        try {
          this.loading = true;
          let call = this.id ? this.updatePage(data) : this.createPage(data);
          let msg = this.id ? "Page Updated" : "Page Created";
          await call;
          this.toast("success", msg);
          this.$router.push({ name: "Pages Content" });
          this.loading = false;
        } catch (err) {
          this.loading = false;
          this.toast("error", "Opps, Something went wrong ");
        }
      }
    },
    setUser(user) {
      this.isOpen = false;
      this.user = user;
    },
    async submitSearch() {
      await this.$store.dispatch("user/getUsers", { keyword: this.search });
    },

    async getPageData() {
      if (this.id) {
        this.fetching = true;
        const { page } = await this.getPage(this.id);
        this.form = {
          id: page.id,
          title: page.title,
          page_url: page.page_url,
          meta_title: page.meta_title,
          meta_description: page.meta_description,
          meta_keywords: page.meta_keywords,
          status: page.status == "live" ? true : false,
        };
      }
    },
  },
  mounted() {
    this.getPageData();
  },
  computed: {
    users() {
      return this.$store.getters["user/users"];
    },
    getting() {
      return this.$store.getters["user/loading"];
    },
    id() {
      return this.$route.params.id;
    },
  },
};
</script>

<style scoped lang="scss">
.feature-attributes {
  width: 100%;
  .wrapper {
    display: flex;
    align-items: center;
    gap: 20px;

    .val {
      flex: 1;
    }

    .attr {
      width: 25%;
    }
  }
}

.s-title {
  margin-bottom: 20px;
  strong {
    font-weight: 700;
  }
}

.image-box {
  height: 130px;
  width: 100%;
  border: 2px dashed #d2d2d2;
  text-align: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;

  span {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 200px;
  }

  .preview {
    height: 100%;
    width: 100%;
    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }
}

.users-search {
  position: relative;

  .users-card {
    width: 100%;
    background: #fff;
    max-height: 300px;
    overflow-y: auto;

    .items {
      padding: 10px;
      cursor: pointer;

      &:hover {
        background: #f9f9f9;
      }
    }
  }
  .selected {
    padding: 15px 10px;
    background: #e3ffe6;
    border-radius: 5px;
    font-weight: 700;
  }
}
</style>
